import { render, staticRenderFns } from "./CardInRow.vue?vue&type=template&id=996c6afa&scoped=true"
import script from "./CardInRow.vue?vue&type=script&lang=js"
export * from "./CardInRow.vue?vue&type=script&lang=js"
import style0 from "./CardInRow.vue?vue&type=style&index=0&id=996c6afa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "996c6afa",
  null
  
)

export default component.exports