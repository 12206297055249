<script>
import Tooltip2Vue from '@/components/Tooltip2Vue/Tooltip2Vue';
import AddBtn from '@/components/buttons/AddBtn';

export default {
  components: {
    Tooltip2Vue,
    AddBtn
  },
  props: {
    heads: {
      type: Array,
      default: () => []
    },
    ganttTime: {
      type: Array,
      default: () => []
    },
    samples: {
      type: Number,
      default: 0
    },
    optionsCollaborators: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    todayDate() {
      return (new Date()).getDate()
    },
    todayMonth() {
      return (new Date()).getMonth()
    }
  },

  methods: {
    handleScrollDate(ev) {
      this.$emit('wheel', ev)
    },
    headerDate(date) {
      let halfDayOfMonth = this.ganttTime.filter(gTime => gTime.month == date.month)
      const firstDayOfMonth = halfDayOfMonth[Math.floor(halfDayOfMonth.length / 2)]
      if (firstDayOfMonth.date == date.date) {
        return date.month
      } else
        return ''
    },
    filterBySearchTitle(ev) {
      const text = ev.target.value.trim().toLowerCase()
      this.$emit('filterBySearchTitle', text)
    },
    filterByResponsable(responsable) {
      if (responsable) {
        this.$emit('filterByResponsable', responsable.personaId)
      } else {
        this.$emit('filterByResponsable', null)
      }
    },
    isToday(date) {
      return date.day == this.todayDate && date.dateNumber.getMonth() == this.todayMonth
    }
  }
}

</script>

<template>
  <div>
    <div v-for="head in heads" :key="head.title" class="item">
      <div v-if="head.name != 'gantt'" class="gantt-col">
        <span>
          {{ head.title }}
        </span>

      </div>


      <span v-else class="gantt-col-date">
        <div v-for="(date, day) in ganttTime" :key="'day' + day" @wheel="handleScrollDate"
          :class="{ 'first-day': date.day == '01' }">
          <div class="date">
            {{ headerDate(date) }}
          </div>
          <div class="day" :class="{ isToday : isToday(date)}">
            {{ date.day }}
          </div>
        </div>
      </span>

      <Tooltip2Vue class="tp-filter" position="bottom" v-if="head.name == 'title'">
        <span class="icon-filter">
          <i class="fas fa-filter"></i>
        </span>
        <template #tooltip="{ close }">
          <div class="tp-container">
            <header>
              <span>
                Filtro | El titulo contiene 
              </span>
              <AddBtn class="close" @click="close" />
            </header>
            <div class="body">
              <input type="text" @input="filterBySearchTitle" class="lego-input" />
            </div>
          </div>
        </template>
      </Tooltip2Vue>


      <Tooltip2Vue class="tp-filter" position="bottom" v-if="head.name == 'assignments'">
        <span class="icon-filter">
          <i class="fas fa-filter"></i>
        </span>
        <template #tooltip="{ close }">
          <div class="tp-container">
            <header>
              <span>
                Filtro | Colaborador asignado
              </span>
              <AddBtn class="close" @click="close" />
            </header>
            <div class="body">
              <v-select class="lego" :options="optionsCollaborators" label="name" @input="filterByResponsable"></v-select>
            </div>
          </div>
        </template>
      </Tooltip2Vue>

    </div>
  </div>
</template>

<style scoped>
.tp-container {
  border: solid 1px rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 8px;
  cursor: auto;
  width: 300px;
  text-align: start;
}

.tp-container header {
  margin-bottom: 1rem;
}

.tp-container span {
  font-size: 1rem;
}

.item {
  position: relative;
}

.tp-filter {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.icon-filter {
  font-size: .8rem;

}

.gantt-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex-direction: column;
}




.gantt-col-date,
:deep(.gantt-col-date) {
  display: grid;
  grid-template-columns: repeat(v-bind(samples), 1fr);
  /* se ubican en la parte inferior */
  /* padding: 0.25rem 0; */

}

.gantt-col-date {
  border: solid 2px rgba(255, 255, 255, 0.05);
}



.gantt-col-date>div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: .4rem;
  font-size: .75rem;
}

.gantt-col-date .day {

  width: 100%;
  box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.05);
}

.first-day {
  border-left: solid 1px rgba(255, 255, 255, 0.05);
}

.isToday {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
